<template>
   <div class="contenedor-estado">
               <div class="nombre-estado">
                   <p>{{nombre}}</p>
               </div>
               <div class="periodos-estado">
                   <div class="periodo-data cumplido">
                       <p>2018</p>
                   </div>
                   <div class="periodo-data cumplido">
                       <p>2019</p>
                   </div>
                   <div class="periodo-data ">
                       <p>2020</p>
                   </div>
               </div>
               <div class="enlace-estados">
                   <a :href="enlace" target="_blank">Consultar Sitio</a>
               </div>
           </div>
</template>

<script>
export default {
    props:{
        nombre: {
            type: String
        },
        enlace:{
            type: String
        }
    }

}
</script>

<style scoped>
     .contenedor-estado{
        width:100%;
        height:90px;
        display:flex; align-items:center; justify-content: space-evenly;
    }
    .nombre-estado{
        width:30%;
        text-align:center;
    }
    .periodos-estado{
        width:33%;
        display:flex; align-items:center; justify-content: space-evenly;
    }
    .periodo-data{
        width: 33%;
        height:100%;
        text-align:center;
        /* background-color:red; */
    }
    .enlace-estados{
        width:33%;
        text-align:center;
    }
</style>