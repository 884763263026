<template>
    <div class="contenedor-dashboard">
        <div class="dashboard-usuario">
            <p><b>{{userNombre}}</b></p>
            <p>{{userDependencia}}</p>
            <p class="cerrar-sesion"> <router-link to='/'>Cerrar Sesión</router-link></p>
        </div>
        <div class="dashboard-inicio">
            <h1>PAIMEF Transparente</h1>
            <div v-for="(estado,index) in estados" :key="index">
                <Estado
                    :nombre="estado.nombre"
                    :enlace="estado.enlace"
                />
            </div>
        </div>
        
    </div>
</template>

<script>

import { useStore } from 'vuex'
import { onMounted, ref } from 'vue'

import Estado from '../components/Estado.vue';

export default {
    components:{
        Estado,
    },
    setup() {
        const store = useStore();
        let userNombre = ref('');
        let userDependencia = ref('')
        const estados = [
            { 
                "nombre" : "Aguascalientes",
                "enlace":"http://aguascalientes.paimeftransparente.com/" 
            }, 
            {
                "nombre":"Baja California",
                "enlace":"http://bajacalifornia.paimeftransparente.com/"
            },
            {
                "nombre":"Baja California Sur",
                "enlace":"http://bajacaliforniasur.paimeftransparente.com/"
            },
            {
                "nombre":"Campeche",
                "enlace":"http://campeche.paimeftransparente.com/"
            },
            {
                "nombre":"Coahuila",
                "enlace":"http://coahuila.paimeftransparente.com/"
            },
            {
                "nombre":"Colima",
                "enlace":"http://colima.paimeftransparente.com/"
            },
            {
                "nombre":"Chiapas",
                "enlace":"http://chiapas.paimeftransparente.com/"
            },
            {
                "nombre":"Chihuahua",
                "enlace":"http://chihuahua.paimeftransparente.com/"
            },
            {
                "nombre":"CDMX",
                "enlace":"http://cdmx.paimeftransparente.com/"
            },
            {
                "nombre":"Durango",
                "enlace":"http://durango.paimeftransparente.com/"
            },
            {
                "nombre":"Guanajuato",
                "enlace":"http://guanajuato.paimeftransparente.com/"
            },
            {
                "nombre":"Guerrero",
                "enlace":"http://guerrero.paimeftransparente.com/"
            },
            {
                "nombre":"Hidalgo",
                "enlace":"http://hidalgo.paimeftransparente.com/"
            },
            {
                "nombre":"Jalisco",
                "enlace":"http://jalisco.paimeftransparente.com/"
            },
            {
                "nombre":"Estado de México",
                "enlace":"http://estadodemexico.paimeftransparente.com/"
            },
            {
                "nombre":"Michoacán",
                "enlace":"http://michoacan.paimeftransparente.com/"
            },
            {
                "nombre":"Morelos",
                "enlace":"http://morelos.paimeftransparente.com/"
            },
            {
                "nombre":"Nayarit",
                "enlace":"http://nayarit.paimeftransparente.com/"
            },
            {
                "nombre":"Nuevo León",
                "enlace":"http://nuevoleon.paimeftransparente.com/"
            },
            {
                "nombre":"Oaxaca",
                "enlace":"http://oaxaca.paimeftransparente.com/"
            },
            {
                "nombre":"Puebla",
                "enlace":"http://puebla.paimeftransparente.com/"
            },
            {
                "nombre":"Querétaro",
                "enlace":"http://queretaro.paimeftransparente.com/"
            },
            {
                "nombre":"Quintana Roo",
                "enlace":"http://quintanaroo.paimeftransparente.com/"
            },
            {
                "nombre":"San Luis Potosí",
                "enlace":"http://sanluispotosi.paimeftransparente.com/"
            },
            {
                "nombre":"Sinaloa",
                "enlace":"http://sinaloa.paimeftransparente.com/"
            },
            {
                "nombre":"Sonora",
                "enlace":"http://sonora.paimeftransparente.com/"
            },
            {
                "nombre":"Tabasco",
                "enlace":"http://tabasco.paimeftransparente.com/"
            },
            {
                "nombre":"Tamaulipas",
                "enlace":"http://tamaulipas.paimeftransparente.com/"
            },
            {
                "nombre":"Tlaxcala",
                "enlace":"http://tlaxcala.paimeftransparente.com/"
            },
            {
                "nombre":"Veracruz",
                "enlace":"http://veracruz.paimeftransparente.com/"
            },
            {
                "nombre":"Yucatán",
                "enlace":"http://yucatan.paimeftransparente.com/"
            },
            {
                "nombre":"Zacatecas",
                "enlace":"http://zacatecas.paimeftransparente.com/"
            }
        ]

        onMounted( () => { 
            userNombre.value = store.state.nombre;
            userDependencia.value = store.state.dependencia
        })

        return {
            userNombre,
            userDependencia,
            estados
        }
    }
}
</script>

<style>
   
</style>